import moment from "moment"
import React, { forwardRef, Suspense, useImperativeHandle, useState } from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"

import CustomSpinner from "components/customSpinner"
import CustomTable from "components/customTable/index"
import { NAV_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { getUserWiseSalesReports, getUserWiseSalesReportsExport, getUserWiseSalesReportsTotal } from "helpers/api_actions"

import ReportFilterComponent from "./filterComponent"

const UserSaleReports = forwardRef((props, ref) => {
  UserSaleReports.displayName = "SalesReport"
  const [salesData, setSalesData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [rowCount, setRowCount] = useState(0)
  const [columnCount, setColumnCount] = useState(0)
  const [years, setYears] = useState([])
  const [reoprtsTotal, setReoprtsTotal] = useState([])
  const [pageSize, setPageSize] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [salesCounts, setSalesCounts] = useState([])
  const [finalRow, setFinalRow] = useState([])
  const [filters, setFilters] = useState({})
  const [appliedFilters, setAppliedFilters] = useState("")
  const [search, setSearch] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        exportReport() {
          let basicData = {
            select: `&page=${pageNo}&per_page=${pageSize}&export=true`,
          }
          getUserWiseSalesReportsExport(
            basicData,
            appliedSeach,
            appliedFilters
          ).then(res => {
            const outputFilename = `SalesReport_${moment().format(
              "DDMMYYYY"
            )}.xlsx`

            var blob = new Blob([res])

            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = outputFilename

            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)
          })
        },
      }
    },
    [pageNo, pageSize, appliedSeach, appliedFilters]
  )

  const serviceWiseDataFetch = () => {
    let basicData = {
      select: `&page=${pageNo}&per_page=${pageSize}`,
    }
    appliedFilters || appliedSeach ? setIsFetching(false) : setIsFetching(true)

    getUserWiseSalesReports(basicData, appliedSeach, appliedFilters).then(res => {
      setYears(Object.keys(res?.data?.data))

      setSalesData(res?.data?.user?.data)
      setSalesCounts(res?.data?.data)
      setTotalCount(res?.data?.user?.total || 0)
      setRowCount(res?.data?.user?.data?.length || 0)
      setColumnCount(Object.keys(res?.data?.data).length || 0)
    })
    getUserWiseSalesReportsTotal(basicData, appliedSeach, appliedFilters).then(
      res => {
        setReoprtsTotal(res?.data?.data)
        setGrandTotal(res?.data?.grandTotal)
        setIsFetching(false)
      }
    )
  }
  useEffect(() => {
    handleFinalRow(reoprtsTotal)
  }, [grandTotal, reoprtsTotal])
  useEffect(() => {
    serviceWiseDataFetch()
  }, [pageNo, pageSize, appliedSeach, appliedFilters])

  const handleFinalRow = details => {
    const totalSalesForDate = []
    totalSalesForDate.unshift("Total", grandTotal)
    for (const date in details) {
      if (
        details.hasOwnProperty(date) &&
        details[date].hasOwnProperty("total_sales")
      ) {
        totalSalesForDate.push(details[date].total_sales)
      }
    }
    setFinalRow(totalSalesForDate)
  }
  const [columns, setColumns] = useState([
    {
      title: "Name",
      field: "user_name",
      fixed: true,
      render: r => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.SALES_REPORT_USER_MANAGEMENT_DETAILS}${r.id}`,
            state: {
              edit: false,
              frompath: AUTH_ROUTES.REPORTS_SALES,
              fromlabel: NAV_LABELS.REPORTS_SALES,
            },
          }}
        >
          {r.name}
        </Link>
      ),
    },
    {
      title: <span style={{ color: "red" }}>Total</span>,
      field: "row_sum",
      borderChange: true,
      fixed: true,
      render: r => (
        <Link className="text-link pe-none text-danger">
          {Number(r.row_sum).toLocaleString()}
        </Link>
      ),
    },
  ])

  const columns1 = years.map(year => ({
    title: year,
    render: cellInfo => {
      const userName = cellInfo.name
      const yearData = salesCounts[year] || []
      const userInfo = yearData.find(
        user => user.user_name === userName
      )
      const totalSales = userInfo ? userInfo.total_sales : 0
      return totalSales
    },
  }))
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.user?.length > 0
        ? "&service_id=" + fil?.user?.map(item => item.id).join(",")
        : ""
    }${
      fil?.service?.length > 0
        ? "&service_id=" + fil?.service?.map(item => item.id).join(",")
        : ""
    }${
      fil?.city?.length > 0
        ? "&city=" + fil?.city?.map(item => item.value).join(",")
        : ""
    }
    ${
      fil?.handledBy?.length > 0
        ? "&cre_user_id=" + fil?.handledBy?.map(item => item.id).join(",")
        : ""
    }${
      fil?.state?.value ? `&state=${encodeURIComponent(fil?.state?.value)}` : ""
    }${
      fil?.leadOwner?.length > 0
        ? "&user_id=" + fil?.leadOwner?.map(item => item.id).join(",")
        : ""
    }${
      fil?.customer?.length > 0
        ? "&lead_id=" + fil?.customer?.map(item => item.id).join(",")
        : ""
    }${
      fil?.flryear?.value ? "&year=" + fil?.flryear?.value  : ""
    }${
      fil?.createdOn?.length === 2
        ? "&start_date=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          "&end_date=" +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `
        &search=${val}
        `
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }
  return (
    <>
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns.concat(columns1)}
          data={salesData}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          filters={filters}
          onFilterSubmission={onFilterSubmission}
          filterComponent={ReportFilterComponent}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          search={search}
          onSearch={handleSearch}
          rowCount={rowCount}
          columnCount={columnCount}
          customDetailsFlag={salesData.length > 0 ?? true}
          customDetails={finalRow}
          isFetching={isFetching}
        />
      </Suspense>
    </>
  )
})
export default UserSaleReports
