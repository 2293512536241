import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import cityIcon from "assets/admin-images/city.svg"
import serviceIcon from "assets/admin-images/service.svg"
import CustomCalander from "components/CustomCalendar/index.jsx"
import SearchSelect from "components/CustomSearchSelect/index.jsx"
import Table from "components/customTable/components/table/Table.jsx"
import TableBody from "components/customTable/components/table/TableBody.jsx"
import TableCell from "components/customTable/components/table/TableCell.jsx"
import TableHead from "components/customTable/components/table/TableHead.jsx"
import TableRow from "components/customTable/components/table/TableRow.jsx"
import { NAV_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes.js"
import {
  getAdminDashboardDetails,
  getCommissionDashboardDetails,
  getSalesDashboardDetails,
  getServicesList,
} from "helpers/api_actions"
import { generateClassNameByValue, isAdmin } from "helpers/utilities.js"

import CommissionsGivenchart from "./tabs/CommissionsGivenchart"
import LeadsGeneratedchart from "./tabs/LeadsGeneratedchart.js"
import SalesRevenueChart from "./tabs/SalesRevenueChart.js"

const Dashboard = props => {
  document.title = "Upfin"
  const [data, setData] = useState()
  const [activeTab, setActiveTab] = useState("1")
  const [activeTabReport, setAactiveTabReport] = useState("1")
  const [selectedDateRange, setSelectedDateRange] = useState()
  const [salesData, setSalesData] = useState()
  const [selectedService, setSelectedService] = useState([])

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard?.chartsData || [],
  }))

  const options = [
    { value: "AllTime", text: "All Time " },
    { value: "ThisMonth", text: "This Month " },
    { value: "LastMonth", text: "Last Month " },
    { value: "LastThreeMonth", text: "Last Three Month " },
    { value: "ThisYear", text: "This Year" },
    { value: "Custom", text: "Custom Date Range" },
  ]

  const [selected, setSelected] = useState(options[0].value)
  var date = new Date()
  var y = date.getFullYear(),
    m = date.getMonth()

  const getdashboardhServiceDetails = () => {
    let payload = { start_date: firstDay, end_date: lastDay }
    getAdminDashboardDetails(payload)
      .then(res => {
        let data = res.data
        setData(data)
      })
      .catch(e => {})
  }

  const getdashboardhSalesDetails = () => {
    setSalesData()
    let payload = { start_date: firstDay, end_date: lastDay }
    let service_ids = selectedService.map(serviceData => serviceData.id)
    if (service_ids.length > 0) {
      payload = {
        ...payload,
        service_id: service_ids.join(","),
      }
    }

    activeTabReport == "1"
      ? getSalesDashboardDetails(payload)
          .then(res => {
            let data = res.data
            setSalesData(data)
          })
          .catch(e => {})
      : getCommissionDashboardDetails(payload)
          .then(res => {
            let data = res.data
            setSalesData(data)
          })
          .catch(e => {})
  }

  const getServices = async v => {
    let payload = {
      select: `id,name&search[name]=${v}&sort_by[name]=ASC`,
    }

    return getServicesList(payload).then(res => {
      let data = res?.data.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })

      return data
    })
  }

  useEffect(() => {
    if (
      selected !== "Custom" ||
      (selected === "Custom" &&
        selectedDateRange &&
        selectedDateRange[0] &&
        selectedDateRange[1])
    ) {
      getdashboardhServiceDetails()
      getdashboardhSalesDetails()
    }
  }, [selected, selectedDateRange, activeTabReport])

  useEffect(() => {
    getdashboardhSalesDetails()
  }, [selectedService, activeTabReport])

  const handleChange = event => {
    setSelected(event.target.value)
    if (event.target.value !== "Custom") {
      setSelectedDateRange(null)
    }
  }

  if (selected === "AllTime") {
    var firstDay = moment("2023-01-01").format("YYYY-MM-DD")
    var lastDay = moment(new Date(y, m + 1, 0)).format("YYYY-MM-DD")
  }

  if (selected === "ThisMonth") {
    var firstDay = moment(new Date(y, m, 1)).format("YYYY-MM-DD")
    var lastDay = moment(new Date(y, m + 1, 0)).format("YYYY-MM-DD")
  } else if (selected === "LastMonth") {
    var firstDay = moment(new Date(y, m - 1, 1)).format("YYYY-MM-DD")
    var lastDay = moment(new Date(y, m, 0)).format("YYYY-MM-DD")
  } else if (selected === "LastThreeMonth") {
    var firstDay = moment(new Date(y, m - 3, 1)).format("YYYY-MM-DD")
    var lastDay = moment(new Date(y, m, 0)).format("YYYY-MM-DD")
  } else if (selected === "ThisYear") {
    var firstDay = moment(new Date(y, 0, 1)).format("YYYY-MM-DD")
    var lastDay = moment(new Date(y, 11, 31)).format("YYYY-MM-DD")
  } else if (
    selected === "Custom" &&
    selectedDateRange &&
    selectedDateRange[0] &&
    selectedDateRange[1]
  ) {
    var firstDay = moment(selectedDateRange[0]).format("YYYY-MM-DD")
    var lastDay = moment(selectedDateRange[1]).format("YYYY-MM-DD")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="summaryData">
          <Row>
            <Col lg="6" className="sumarry">
              Summary Data
            </Col>
            <Col lg="6" className="summarydropdown">
              <Row className="align-right">
                <Col lg="6" className="ml-auto">
                  <select
                    className="form-control maxWidth maxContentWidth ml-auto"
                    value={selected}
                    onChange={handleChange}
                  >
                    {options.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </Col>
                {selected === "Custom" && (
                  <Col lg="6">
                    <CustomCalander
                      dateRange
                      placeholder={"Start Date - End Date"}
                      value={selectedDateRange}
                      onChange={date => setSelectedDateRange(date)}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <Card className="list gap10">
                <Row>
                  <Col lg="6" className="listtitle">
                    Total Users
                  </Col>
                  <Col lg="6" className="listvalue">
                    <Link to={`${AUTH_ROUTES.USERS}`}>
                      {data?.Users?.total}
                    </Link>
                  </Col>
                </Row>

                <div className="listspace">
                  <Row className="listspacebottom">
                    <Col lg="6" className="listsubtitle">
                      Active
                    </Col>
                    <Col lg="6" className="listsubvalue">
                      <Link
                        to={{
                          pathname: `${AUTH_ROUTES.USERS}`,
                          state: { status: "Active" },
                        }}
                      >
                        {data?.Users?.active}
                      </Link>
                    </Col>
                  </Row>
                  <Row className="listspacebottom">
                    <Col lg="6" className="listsubtitle">
                      In Active
                    </Col>
                    <Col lg="6" className="listsubvalue">
                      <Link
                        to={{
                          pathname: `${AUTH_ROUTES.USERS}`,
                          state: { status: "Inactive" },
                        }}
                      >
                        {data?.Users?.inactive}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg="8">
              <Card className="list gap10">
                <Row>
                  <Col lg="6" className="listtitle">
                    Top Performing Services
                  </Col>
                  <Col lg="6" className="listIcon">
                    <div className="iconBox">
                      <img src={serviceIcon} />
                    </div>
                  </Col>
                </Row>
                <div className="listspace">
                  <Row className="listspacebottom">
                    <Row className="gap10">
                      {data?.TopPerformingServices.map((item, i) => {
                        return (
                          <Col lg="6" className="listspacebottom" key={i}>
                            <Row>
                              <Col lg="10" className="listsubtitle">
                                {item.name}
                              </Col>
                              <Col lg="2" className="listsubvalue">
                                {isAdmin() ? (
                                  <Link
                                    to={{
                                      pathname: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${item.service_id}`,
                                      state: { tab: "service-leads" },
                                    }}
                                  >
                                    {item.count}
                                  </Link>
                                ) : (
                                  <span>{item.count}</span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                    </Row>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <Card className="list gap10">
                <Row>
                  <Col lg="6" className="listtitle">
                    Total Leads
                  </Col>
                  <Col lg="6" className="listvalue">
                    {data?.TotalLeads?.count}
                  </Col>
                </Row>

                <div className="listspace">
                  <Row className="listspacebottom">
                    <Col lg="6" className="listsubtitle">
                      General Leads
                    </Col>
                    <Col lg="6" className="listsubvalue">
                      <Link to={`${AUTH_ROUTES.LEADS}`}>
                        {data?.GeneralLead?.count}
                      </Link>
                    </Col>
                  </Row>
                  <Row className="listspacebottom">
                    <Col lg="6" className="listsubtitle">
                      Business Leads
                    </Col>
                    <Col lg="6" className="listsubvalue">
                      <Link to={`${AUTH_ROUTES.BUSINESS}`}>
                        {data?.BusinessLead?.count}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg="8">
              <Card className="list gap10">
                <Row>
                  <Col lg="6" className="listtitle">
                    Top Performing City
                  </Col>
                  <Col lg="6" className="listIcon">
                    <div className="iconBox">
                      <img src={cityIcon} />
                    </div>
                  </Col>
                </Row>
                <div className="listspace">
                  <Row className="listspacebottom">
                    <Row className="gap10">
                      {data?.TopPerformingCity.map((item, i) => {
                        return (
                          <Col lg="6" className="listspacebottom" key={i}>
                            <Row>
                              <Col lg="10" className="listsubtitle">
                                {item.city}
                              </Col>
                              <Col lg="2" className="listsubvalue">
                                {item.count}
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                    </Row>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <Card className="list gap10">
                <Row>
                  <Col lg="9" className="listtitle">
                    Total Customer Support Tickets
                  </Col>
                  <Col lg="3" className="listvalue">
                    <Link to={`${AUTH_ROUTES.CUSTOMER_SUPPORT}`}>
                      {data?.customersupport?.count}
                    </Link>
                  </Col>
                </Row>

                <div className="listspace">
                  {data?.customersupport?.data &&
                  data?.customersupport?.data.length > 0 ? (
                    data?.customersupport?.data.map(customersupportData => (
                      <Row
                        className="listspacebottom mb-1"
                        key={customersupportData?.id}
                      >
                        <Col lg="9" className="listsubtitle">
                          <Link
                            to={`${AUTH_ROUTES.CUSTOMER_SUPPORT_DETAILS}${customersupportData.id}`}
                          >
                            <span
                              style={{
                                display: "block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={customersupportData?.subject}
                            >
                              {customersupportData?.subject}
                            </span>
                          </Link>
                        </Col>
                        <Col lg="3" className="listsubvalue">
                          <span
                            className={`ms-2 chips ${generateClassNameByValue(
                              customersupportData?.status
                            )}`}
                          >
                            {customersupportData?.status}
                          </span>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row className="listspacebottom">
                      <Col lg="12" className="listsubtitle">
                        No data found
                      </Col>
                    </Row>
                  )}
                </div>
              </Card>
            </Col>

            <Col lg="4">
              <Card className="list gap10">
                <Row>
                  <Col lg="9" className="listtitle">
                    Total Partnership Requests
                  </Col>
                  <Col lg="3" className="listvalue">
                    <Link to={`${AUTH_ROUTES.PARTNERSHIP_REQUESTS}`}>
                      {data?.partnershipform?.count}
                    </Link>
                  </Col>
                </Row>

                <div className="listspace">
                  {data?.partnershipform?.data &&
                  data?.partnershipform?.data.length > 0 ? (
                    data?.partnershipform?.data.map(partnershipformData => (
                      <Row
                        className="listspacebottom mb-1"
                        key={partnershipformData?.id}
                      >
                        <Col lg="9" className="listsubtitle">
                          <Link
                            to={`${AUTH_ROUTES.PARTNERSHIP_REQUEST_DETAILS}${partnershipformData.id}`}
                          >
                            <span
                              style={{
                                display: "block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={partnershipformData?.name}
                            >
                              {partnershipformData?.name}
                            </span>
                          </Link>
                        </Col>
                        <Col lg="3" className="listsubvalue">
                          <span
                            className={`ms-2 chips ${generateClassNameByValue(
                              partnershipformData?.status
                            )}`}
                          >
                            {partnershipformData?.status}
                          </span>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row className="listspacebottom">
                      <Col lg="12" className="listsubtitle">
                        No data found
                      </Col>
                    </Row>
                  )}
                </div>
              </Card>
            </Col>

            <Col lg="4">
              <Card className="list gap10">
                <Row>
                  <Col lg="9" className="listtitle">
                    Total Tickets
                  </Col>
                  <Col lg="3" className="listvalue">
                    <Link to={`${AUTH_ROUTES.TICKETS}`}>
                      {data?.ticket?.count}
                    </Link>
                  </Col>
                </Row>

                <div className="listspace">
                  {data?.ticket?.data && data?.ticket?.data.length > 0 ? (
                    data?.ticket?.data.map(ticketData => (
                      <Row
                        className="listspacebottom mb-1"
                        key={ticketData?.id}
                      >
                        <Col lg="9" className="listsubtitle">
                          <Link
                            to={`${AUTH_ROUTES.TICKET_DETAILS}${ticketData.id}`}
                          >
                            <span
                              style={{
                                display: "block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={ticketData?.issue}
                            >
                              {ticketData?.issue}
                            </span>
                          </Link>
                        </Col>
                        <Col lg="3" className="listsubvalue">
                          <span
                            className={`ms-2 chips ${generateClassNameByValue(
                              ticketData?.status
                            )}`}
                          >
                            {ticketData?.status}
                          </span>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row className="listspacebottom">
                      <Col lg="12" className="listsubtitle">
                        No data found
                      </Col>
                    </Row>
                  )}
                </div>
              </Card>
            </Col>
          </Row>

          <Row className="Dashboardchart m-0">
            <Col lg="12" className="chartdatas">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {/* <LeadsGeneratedchart dataColors='["--bs-primary", "--bs-warning", "--bs-info"]'></LeadsGeneratedchart> */}
                </TabPane>
              </TabContent>
            </Col>
          </Row>

          {isAdmin() && (
            <Row>
              <Col lg="12">
                <Card className="list gap10">
                  <Nav tabs className="nav-tabs-custom ">
                    <NavItem>
                      <NavLink
                        className={activeTabReport == "1" ? "active" : ""}
                        onClick={() => setAactiveTabReport("1")}
                      >
                        Sales Report
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTabReport == "2" ? "active" : ""}
                        onClick={() => setAactiveTabReport("2")}
                      >
                        Commissions Report
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-lg-4">
                      <SearchSelect
                        getData={getServices}
                        isMulti={true}
                        placeholder="Select Service"
                        menuPlacement="bottom"
                        autoload={true}
                        setData={v => {
                          setSelectedService(v)
                        }}
                        value={selectedService}
                      />
                    </div>
                  </div>
                  <div className="overflow-auto">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell key="table-head-services">
                            Services
                          </TableCell>
                          {salesData?.data &&
                            Object.keys(salesData?.data).map(dataKey => (
                              <TableCell key={`table-head-${dataKey}`}>
                                {dataKey}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {salesData?.service.length ? (
                          salesData?.service.map(serviceData => (
                            <TableRow key={`service-data-${serviceData?.id}`}>
                              <TableCell>
                                {" "}
                                <Link
                                  className="text-link"
                                  to={{
                                    pathname: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${serviceData?.id}`,
                                    state: {
                                      edit: false,
                                      frompath: AUTH_ROUTES.DASHBOARD,
                                      fromlabel: NAV_LABELS.DASHBOARD,
                                    },
                                  }}
                                >
                                  {serviceData?.name}
                                </Link>
                              </TableCell>
                              {salesData?.data &&
                                Object.keys(salesData?.data).map(
                                  (month, key) => {
                                    let serviceWiseData = salesData?.data[
                                      month
                                    ].find(
                                      servicemonthlyData =>
                                        servicemonthlyData?.service_id ===
                                        serviceData?.id
                                    )
                                    return (
                                      <TableCell
                                        key={`service-monthly-data-${serviceData?.id}-${month}`}
                                      >
                                        {serviceWiseData?.total_sales}
                                      </TableCell>
                                    )
                                  }
                                )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell>No data found</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          )}

          <Row className="LeadsDashboard m-0">
            <Col lg="12">
              <p className="title ">
                {" "}
                <b>Leads by Status</b>
              </p>
            </Col>
            <Col lg="12" className="leadlist">
              <div>
                <p className="leadsubtitle">New</p>
                <p className="leadvalues">{data?.LeadsByStatus.New}</p>
              </div>
              <div>
                <p className="leadsubtitle">Working</p>
                <p className="leadvalues">{data?.LeadsByStatus.Working}</p>
              </div>
              <div>
                <p className="leadsubtitle">Nurturing</p>
                <p className="leadvalues">{data?.LeadsByStatus.Nurturing}</p>
              </div>
              <div>
                <p className="leadsubtitle">Qualified</p>
                <p className="leadvalues">{data?.LeadsByStatus.Qualified}</p>
              </div>
              <div>
                <p className="leadsubtitle">Completed</p>
                <p className="leadvalues">{data?.LeadsByStatus.Completed}</p>
              </div>
              <div>
                <p className="leadsubtitle">Converted</p>
                <p className="leadvalues">{data?.LeadsByStatus.Converted}</p>
              </div>

              <div>
                <p className="leadsubtitle">Unqualified</p>
                <p className="leadvalues">{data?.LeadsByStatus.Unqualified}</p>
              </div>
            </Col>
          </Row>

          <Row className="Dashboardchart m-0">
            <Col lg="12" className="chartdatas">
              <Nav tabs className="nav-tabs-custom ">
                <NavItem>
                  <NavLink
                    className={activeTab == "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    Leads Generated
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab == "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    Commissions Given
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab == "3" ? "active" : ""}
                    onClick={() => setActiveTab("3")}
                  >
                    Sales Generated
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <LeadsGeneratedchart dataColors='["--bs-primary", "--bs-warning", "--bs-info"]'></LeadsGeneratedchart>
                </TabPane>
                <TabPane tabId="2">
                  <CommissionsGivenchart dataColors='["--bs-primary", "--bs-info"]'></CommissionsGivenchart>
                </TabPane>
                <TabPane tabId="3">
                  <SalesRevenueChart dataColors='["--bs-primary", "--bs-info"]'></SalesRevenueChart>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </div>

      {/* subscribe ModalHeader */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
