import React, { lazy, Suspense, useState } from "react"
import { withRouter } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Col, Container, Input, Modal, Row } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import CustomTextField from "components/CustomTextField/index"
import { BUTTON_LABELS, HEADER_LABELS } from "constants/label"
import { DEFAULT_CLASSES } from "constants/styles"
import { postPresaleCreUserAssign, postPreSaleImport } from "helpers/api_actions"
import { generateErrorMessage, isAdmin } from "helpers/utilities"

const PresalesListing = lazy(() => import("./component/list"))
const PreSales = props => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [assignCreModalOpen, setAssignCreModalOpen] = useState(false)
  const [formData, setFormData] = useState({})
  const [filters, setAppliedFilters] = useState("")
  const { addToast } = useToasts()
  const [error, setError] = useState("")
  const [nameError, setNameError] = useState("")
  const [count, setCount] = useState(0)
  const maxSize = 2 * 1024 * 1024

  const headerActions =
    count > 0
      ? isAdmin() && [
          {
            type: "button",
            label: BUTTON_LABELS.ASSIGN_CRE_USER,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setAssignCreModalOpen(true)
            },
          },
          {
            type: "button",
            label: BUTTON_LABELS.IMPORT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setIsImportModalOpen(true)
            },
          },
        ]
      : isAdmin() && [
          {
            type: "button",
            label: BUTTON_LABELS.IMPORT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setIsImportModalOpen(true)
            },
          },
        ]
  const validateFile = (value, fileType) => {
    if (!value) {
      setError("Please choose an attachment")
      return false
    } else {
      if (value.type !== fileType) {
        setError("Invalid file type")
        return false
      }
      if (value.size > maxSize) {
        setError("File size exceeds the maximum allowed size.")
        return false
      }
    }
    return true
  }

  const toggleImportModal = () => {
    setIsImportModalOpen(!isImportModalOpen)
    // this.removeBodyCss()
  }

  const handleImportFileChange = e => {
    setError("")
    setNameError("")

    let value = e.target.files[0]
    let error = validateFile(value, "text/csv")
    if (error) {
      setFormData({ ...formData, file: value })
    }
  }
  const handleChange = (v, k) => {
    setNameError("")
    if (v !== "") {
      setFormData({ ...formData, file_name: v })
    } else {
      setNameError("File name is required")
    }
  }

  const setFilters = filters => {
    setAppliedFilters(filters)
  }

  const validateImportLog = () => {
    let isValid = true
    if (!formData?.file) {
      setError("Please choose an attachment")
      isValid = false
    }
    if (!formData?.file_name || /^\s*$/.test(formData?.file_name)) {
      setNameError("File Name is required")
      isValid = false
    }
    return isValid
  }

  const submitData = () => {
    let payload = { file: formData?.file, file_name: formData?.file_name }

    let isValid = validateImportLog()
    if (isValid) {
      postPreSaleImport(payload)
        .then(res => {
          addToast(res?.message, { appearance: "success" })
          handleClose()
          setTimeout(() => {
            window.location.reload()
          }, 500)
        })

        .catch(err => {
          // toastr.error(err.response.data.message)
          if (err?.response?.data?.message) {
            addToast(generateErrorMessage(err?.response?.data), {
              appearance: "error",
            })
          }
        })
    }
  }

  function handleAssignSubmit(data) {
    if (!data?.select_all && (!data?.include || data?.include.length < 1)) {
      addToast("Select atleast one user to assign", {
        appearance: "error",
      })
    } else {
      postPresaleCreUserAssign(data)
        .then(res => {
          setAssignCreModalOpen(false)
          setTimeout(() => {
            addToast(res?.message, { appearance: "success" })

            setTimeout(() => {
              window.location.reload()
            }, 2500)
          }, 2000)
        })
        .catch(err => {
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    }
  }
  const handleClose = () => {
    setIsImportModalOpen(false)
    setError("")
    setNameError("")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={HEADER_LABELS.PRESALES}
              actions={headerActions}
            />{" "}
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <PresalesListing
                  setFilters={setFilters}
                  {...props}
                  assignCreModalOpen={assignCreModalOpen}
                  setAssignCreModalOpen={setAssignCreModalOpen}
                  onAssignSubmit={handleAssignSubmit}
                  setCount={setCount}
                />
              </Suspense>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={isImportModalOpen}
          toggle={toggleImportModal}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Import Presale Leads
            </h5>
            <button
              type="button"
              onClick={() => {
                // setIsImportModalOpen(false)
                handleClose()
              }}
              className="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <form>
              <div className=" mb-3">
                <Input
                  className="form-control"
                  type="file"
                  accept="text/csv"
                  // disabled={props.isReadOnly}
                  id="formFile"
                  name="importFile"
                  onChange={handleImportFileChange}
                />
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className=" mb-3">
                <div className=" mb-3">
                  <CustomTextField
                    label="File Name"
                    required
                    placeholder="Enter File Name"
                    type="text"
                    name="name"
                    onChange={(v, k, e) => handleChange(v, k, e)}
                  />
                  {nameError && <p className="text-danger">{nameError}</p>}
                </div>
              </div>
              <div className="mb-3">
                <p>
                  Maximum upload file size: <strong>10 MB</strong>{" "}
                </p>
                <p>
                  File types supported: <strong>csv</strong>{" "}
                </p>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                // setIsImportModalOpen(false)
                handleClose()
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                !error && submitData()
              }}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PreSales)
